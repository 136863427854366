import { initNavigation } from './modules/navigation';
import { scrollListener } from './modules/scroll';
import { mountHeroSlider } from './modules/slider';
import { initReveal } from './modules/reveal';
import { initPractice } from './modules/practice';
import { sideNav } from './modules/side-nav';
import { initFilterBack } from './modules/filter-back';
import { initVideos } from './modules/video';
import { subnav } from './modules/subnav';
import { search } from './modules/search';
import { initShuffle } from './modules/shuffle';
import { carousel } from './modules/carousel';
import { setupLazyImages } from './modules/lazy-image';

mountHeroSlider();
initNavigation();
initPractice();
scrollListener();
sideNav();
initFilterBack();
initVideos();
subnav();
search();
initShuffle();
carousel();
setupLazyImages();

// We have to wait for the page to load for reveal animations
// to be setup so height is calculated correctly
window.addEventListener('load', function () {
  initReveal();
});
