const shuffle = (container) => {
  for (var i = container.children.length; i >= 0; i--) {
    container.appendChild(container.children[(Math.random() * i) | 0]);
  }
};

export const initShuffle = () => {
  const containerToShuffle = document.querySelectorAll('.js-shuffle');

  if (containerToShuffle.length) {
    containerToShuffle.forEach((container) => {
      shuffle(container);
    });
  }
};
