import Player from '@vimeo/player';

function initVideo(el) {
  new Player(el, {
    url: el.href || el.dataset.vimeoUrl
  });
}

export const initVideos = () => {
  const els = document.querySelectorAll('.js-video');
  els.forEach((el) => initVideo(el));
};
