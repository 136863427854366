import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';
import Player from '@vimeo/player';

let carousels = [];

function resizeListener() {
  // Setup a timer
  let timeout;
  // Listen for resize events
  window.addEventListener(
    'resize',
    function () {
      // If there's a timer, cancel it
      if (timeout) {
        window.cancelAnimationFrame(timeout);
      }
      // Setup the new requestAnimationFrame()
      timeout = window.requestAnimationFrame(function () {
        // Run our resize functions
        carousels.forEach((carouselObject) => carouselObject.carousel.resize());
      });
    },
    false
  );
}

function initVideo(el, carouselObject) {
  const video = new Player(el, {
    url: el.href,
    controls: true
  });

  carouselObject.videos.push(video);
}

function initCarousel(el) {
  // Wrap it in an object so we scope videos to their respective carousel parents.
  let carouselObject = {
    carousel: {},
    videos: []
  };

  carouselObject.carousel = new Flickity(el, {
    freeScroll: false,
    contain: false,
    cellAlign: 'left',
    imagesLoaded: true,
    pageDots: false,
    prevNextButtons: false,
    setGallerySize: false,
    wrapAround: false,
    on: {
      ready: function () {
        const videoEls = document.querySelectorAll('.js-slider-video');
        videoEls.forEach((el) => {
          initVideo(el, carouselObject);
        });
        carousels.push(carouselObject);
      },
      change: function () {
        carouselObject.videos.forEach((video) => video.pause());
      }
    }
  });

  const { carousel } = carouselObject;
  const parent = el.parentElement;
  const prev = parent.querySelector('.js-carousel-prev');
  const next = parent.querySelector('.js-carousel-next');
  const numberEl = parent.querySelector('.js-carousel-index');
  let index = parseInt(numberEl.innerHTML);

  prev.addEventListener('click', function () {
    carousel.previous();
    if (index > 1) {
      index--;
      numberEl.innerHTML = index;
    }
  });

  next.addEventListener('click', function () {
    carousel.next();
    if (index < carousel.cells.length) {
      index++;
      numberEl.innerHTML = index;
    }
  });

  // Update the number is carousel is dragged
  carousel.on('settle', function(index) {
    numberEl.innerHTML = index + 1;
  });
}

export const carousel = () => {
  const els = document.querySelectorAll('.js-carousel');
  els.forEach((el) => initCarousel(el));
  resizeListener();
};
