import Glide, { Controls, Swipe } from '@glidejs/glide/dist/glide.modular.esm';

export const mountHeroSlider = function () {
  let glide;
  const glideEl = document.querySelector('.glide');

  if (glideEl) {
    glide = new Glide(glideEl, {
      type: 'carousel',
      gap: 0,
      animationDuration: 800,
      animationTimingFunc: 'cubic-bezier(0.230, 1.00, 0.320, 1.000)'
    });

    glide.on('run.before', function () {
      glideEl.classList.add('is-running');
      glideEl.classList.add('slider-is-moving');
    });

    glide.on('run.after', function () {
      glideEl.classList.remove('slider-is-moving');
    });

    glide.mount({ Controls, Swipe });
  }
};
